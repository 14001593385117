import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/footer";
import "./mediaCoverageList.css";
import { getMediaCoverageDetails, updateShootDataLink } from "../context/endpoints"; // Add your endpoint here

const MediaCoveragePreview = () => {
  const { id } = useParams(); // Grab the ID from the URL
  const [coverageDetails, setCoverageDetails] = useState(null);
  const [shootDataLink, setShootDataLink] = useState(""); // State for the new field
  const [isSubmitting, setIsSubmitting] = useState(false); // State for submission loading

  useEffect(() => {
    const fetchCoverageDetails = async () => {
      try {
        const data = await getMediaCoverageDetails(id); // Fetch the media coverage details by ID
        setCoverageDetails(data);
      } catch (error) {
        console.error("Error fetching media coverage details:", error);
      }
    };

    fetchCoverageDetails();
  }, [id]);

  const handleShootDataLinkChange = (e) => {
    setShootDataLink(e.target.value);
  };

  const handleShootDataLinkSubmit = async () => {
    setIsSubmitting(true); // Set loading state
    try {
      await updateShootDataLink(id, { shootDataLink }); // Call the endpoint and pass the data
      alert("Shoot Data Link updated successfully!");
    } catch (error) {
      console.error("Error updating Shoot Data Link:", error);
      alert("Failed to update Shoot Data Link. Please try again.");
    } finally {
      setIsSubmitting(false); // Reset loading state
    }
  };

  if (!coverageDetails) {
    return <div>Loading...</div>;
  }

  return (
    <>
     <Helmet>
      <title>Preview Media Coverage</title>
    </Helmet>
    <Header />
    <div className="preview-container">
      <h1>Preview: {coverageDetails.advisor}'s Media Coverage</h1>
      <div className="coverage-details">
        <p><strong>Advisor:</strong> {coverageDetails.advisor}</p>
        <p><strong>Listing Status:</strong> {coverageDetails.listingStatus}</p>
        <p><strong>Exclusive:</strong> {coverageDetails.isExclusive}</p>
        <p><strong>Vacant:</strong> {coverageDetails.isVacant}</p>
        <p><strong>Country:</strong> {coverageDetails.country}</p>
        <p><strong>Community:</strong> {coverageDetails.community}</p>
        <p><strong>Sub-Community:</strong> {coverageDetails.subCommunity || "N/A"}</p>
        <p><strong>Street Number:</strong> {coverageDetails.streetNumber || "N/A"}</p>
        <p><strong>Floor Number:</strong> {coverageDetails.floorNumber}</p>
        <p><strong>Unit Number:</strong> {coverageDetails.unitNumber || "N/A"}</p>
        <p><strong>Listing Type:</strong> {coverageDetails.listingType}</p>
        <p><strong>Property Type:</strong> {coverageDetails.propertyType}</p>
        <p><strong>Bedrooms:</strong> {coverageDetails.bedrooms}</p>
        <p><strong>Asking Price:</strong> {coverageDetails.askingPrice}</p>
        <p><strong>Unique Selling Points:</strong></p>
        <ul>
          {coverageDetails.uniqueSellingPoints.map((usp, index) => (
            <li key={index}>{usp}</li>
          ))}
        </ul>
        <p><strong>Villa/ Apartment No:</strong>{coverageDetails.villaOrAppartment || "N/A"}</p>
        <p><strong>Access Information:</strong> {coverageDetails.accessInfo || "N/A"}</p>
        <p><strong>Additional Notes:</strong> {coverageDetails.additionalNotes || "N/A"}</p>
        <p><strong>Photographer:</strong> {coverageDetails.photographer}</p>
        <p><strong>Date:</strong> {new Date(coverageDetails.date).toLocaleDateString()}</p>
        <p><strong>Time Slot:</strong> {coverageDetails.timeSlot.join(", ")}</p>
        <p><strong>Status:</strong> {coverageDetails.status}</p>
        <p><strong>Created At:</strong> {new Date(coverageDetails.createdAt).toLocaleString()}</p>
        <p><strong>Updated At:</strong> {new Date(coverageDetails.updatedAt).toLocaleString()}</p>

        {/* Google Maps Link */}
        <p><strong>Google Maps Location Link:</strong></p>
        <a href={coverageDetails.googleMapsLink} target="_blank" rel="noopener noreferrer">
          {coverageDetails.googleMapsLink || "No link available"}
        </a>
          {/* Displaying Google Maps Link */}
          <p>
            <strong>Shoot Coverage Data Link:</strong>
            <div className="shoot-data-link-container">
              <p><strong>Enter the data link:</strong></p>
              <input
                id="shoot-data-link"
                type="text"
                value={shootDataLink}
                onChange={handleShootDataLinkChange}
                placeholder="Enter Shoot Coverage Data Link"
                className="shoot-data-link-input"
              />
              <button
                onClick={handleShootDataLinkSubmit}
                disabled={isSubmitting || !shootDataLink} // Disable button if submitting or no data
                className="shoot-data-link-submit-button"
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MediaCoveragePreview;
