import React, { useEffect } from 'react';
import './WhyChooseUs.css';

const WhyChooseUs = () => {
  useEffect(() => {}, []);

  return (
    <section className="why-choose-us-section">
      <h1 className="main-heading">Why Choose Us?</h1>
      <h2 className="sub-heading">
        At BlackBrick, we go beyond helping you relocate, we help you redefine what home means. Through trust, connection, and commitment, we turn the extraordinary into reality, delivering dream homes and lifestyle.
      </h2>
      <div className="content-container">
        <div className="left-column">
          <img
            src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/landingcom8part1.jpg" // Replace with your image path
            alt="Luxury Image"
            className="image"
          />
          <h3 className="quote-heading">Your Journey, Our Commitment</h3>
          <p className="description">
            At BlackBrick, we understand that relocating is more than a move, it’s a life-changing decision. That’s why we’re here to make the process as smooth and reassuring as possible. Our team of seasoned professionals and innovators pairs cutting-edge technology with a personal touch, creating a tailored experience that’s both efficient and deeply human. Trust is the cornerstone of everything we do; with open communication and honest collaboration, we build partnerships that go beyond transactions, offering you peace of mind every step of the way.
          </p>
        </div>
        <div className="right-column">
          <h3 className="quote-heading">More Than a Move, a Partnership</h3>
          <p className="luxury-text">
            Relocation is a journey, and at BlackBrick, we walk it with you. We believe in the power of personal connections, treating every client like an individual with unique dreams and goals. Our philosophy is rooted in trustworthiness and care, blending expertise with empathy to deliver results that don’t just meet expectations, they surpass them. With BlackBrick, you’re not just finding a home; you’re finding a partner you can rely on as you embark on this exciting new chapter.
          </p>
          <img
            src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/landingcom8part2.jpg" // Replace with your image path
            alt="Luxury Image"
            className="image"
          />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
