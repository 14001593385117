import React, { useState } from 'react';
import './ContactForm.css'; // Import the external CSS file

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic (e.g., send the form data to the server)
    console.log('Form Submitted', formData);
  };
  // useEffect(() => {
  //   if (formRef.current) {
  //     formRef.current.focus();
  //   }
  // }, []);

  return (
      <div id="contact-form-section" className="contact-form-section">      
      <div className="contact-form-left">
        {/* Contact Form */}
        <h3 className="contact-form-heading">Let’s Get You Started on Your Journey to Dubai</h3>
        <p className="contact-form-description">
          Relocating to Dubai is an exciting step, and we’re here to make the process as seamless as possible. Share a few details with us, and our team of experts will get in touch to guide you through every step from finding the perfect property to navigating documentation and beyond.
        </p>
        <form onSubmit={handleSubmit} className="contact-form">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            className="contact-form-input"
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            className="contact-form-input"
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            className="contact-form-textarea"
          />
          <button type="submit" className="contact-form-button">Send Message</button>
        </form>
      </div>

      {/* Separator */}
      <div className="contact-form-separator"></div>

      <div className="contact-form-right">
        {/* Social Media Links */}
        <div className="social-media-links">
          <h3 className="social-media-heading">Follow Us</h3>
          <div className="social-media-link">
            <a href="https://facebook.com" target="_blank" className="social-media-link-text">Facebook</a>
          </div>
          <div className="social-media-link">
            <a href="https://linkedin.com" target="_blank" className="social-media-link-text">Linkedin</a>
          </div>
          <div className="social-media-link">
            <a href="https://instagram.com" target="_blank" className="social-media-link-text">Instagram</a>
          </div>
        </div>

        {/* Content Section */}
        <div className="content-section">
          <h3 className="content-heading">About Us</h3>
          <p className="content-description">
            We are determined to prove that we can achieve better results for our customers by leveraging the human connection and simply deliver our promise.
          </p>
        </div>

        {/* Office Address */}
        <div className="address-section">
          <h3 className="address-heading">Our Office</h3>
          <p className="address-details">
            Zabeel House<br />
            Unit 1114<br />
            Onyx Tower 2<br />
            Dubai, UAE
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
