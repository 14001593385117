import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "./FeaturedProperties.css";

const FeaturedProperties = () => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight, // Scroll to the bottom of the page
      behavior: 'smooth', // Smooth scrolling animation
    });
  };


  
  const properties = [
    {
      title: "Luxury Villa in Palm Jumeirah",
      location: "Dubai, UAE",
      image: "https://images.goyzer.com/uf/5063/unit/9283/unnamed_-1-.png?group=5063",
      price: "$10M",
      bedrooms: 5,
      bathrooms: 6,
    },
    {
      title: "Modern Apartment Downtown",
      location: "Downtown Dubai, UAE",
      image: "https://images.goyzer.com/uf/5063/unit/9177/MAZY5247.jpg?group=5063",
      price: "$2M",
      bedrooms: 2,
      bathrooms: 2,
    },
    {
      title: "Beachfront Condo",
      location: "Dubai Marina, UAE",
      image: "https://images.goyzer.com/uf/5063/unit/8935/DSC01455_202408081658370407.jpg?group=5063",
      price: "$3M",
      bedrooms: 3,
      bathrooms: 3,
    },
  ];

  return (
    <div>
      {/* Featured Properties Heading */}
      <section className="featured-properties-heading">
        <h2>Featured Properties</h2>
      </section>

      {/* Slider Section */}
      <section className="featured-property-slider">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          navigation
          className="relocate-swipe"
        >
          {properties.map((property, index) => (
            <SwiperSlide key={index} >
              <div
                className="featured-property-slide-item"
                style={{ backgroundImage: `url(${property.image})` }}
              >
                <div className="property-overlay-content">
                  <h3>{property.title}</h3>
                  <p>{property.location}</p>
                  <p>{property.bedrooms} Bedrooms | {property.bathrooms} Bathrooms</p>
                  <button onClick={scrollToBottom} className="view-property-button">GET STARTED TODAY</button>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      {/* Property Details Section */}
      <section className="property-list-container">
        {properties.map((property, index) => (
          <div className="property-card-item" key={index}>
            <img src={property.image} alt={property.title} />
            <div className="property-info">
              <div className="property-details-left">
                <h4>{property.title}</h4>
                <p>{property.location}</p>
                <p className="property-price">{property.price}</p>
              </div>
              <div className="property-details-right">
                <p>{property.bedrooms} Bedrooms</p>
                <p>{property.bathrooms} Bathrooms</p>
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default FeaturedProperties;
