import React, { useMemo } from "react";
import "./Communities.css";
import { getSettingsFn } from "../../context/endpoints";
import { useQuery } from "@tanstack/react-query";

const Communities = () => {
  // Fetch settings data
  const { isPending, error, data } = useQuery({
    queryKey: ["settingsData"],
    queryFn: getSettingsFn,
  });

  // Assign data to websitesettings if available
  const websitesettings = !isPending && !error ? Object.assign({}, ...data) : {};

  // Collections data
  const collections = useMemo(
    () => [
      {
        name: "Victory Heights",
        image: "https://blackbrickwebsite.s3.me-central-1.amazonaws.com/vh.jpg",
        stats: [
          { label: "Known For", text: "Golf course views and family-friendly environment." },
          { label: "Highlights", text: "Sports clubs, parks, and proximity to top schools." },
          { label: "Ideal For", text: "Families seeking a serene lifestyle near golf courses." },
        ],
      },
      {
        name: "Dubai Marina",
        image: "https://blackbrickwebsite.s3.me-central-1.amazonaws.com/marinageneric.jpg",
        stats: [
          { label: "Known For", text: "Iconic waterfront living with luxury high-rises." },
          { label: "Highlights", text: "Marina Walk, yacht clubs, world-class dining, nightlife, and retail options." },
          { label: "Ideal For", text: "Young professionals, expats, and those seeking a vibrant, urban lifestyle with stunning waterfront views." },
        ],
      },
      {
        name: "Meadows",
        image: "https://blackbrickwebsite.s3.me-central-1.amazonaws.com/meadowsgeneric.jpg",
        stats: [
          { label: "Known For", text: "Quiet, family-oriented villa communities." },
          { label: "Highlights", text: "Landscaped parks, lakes, and proximity to schools and retail." },
          { label: "Ideal For", text: "Families looking for a peaceful neighborhood with modern amenities." },
        ],
      },
      {
        name: "Jumeirah Golf Estates",
        image: "https://blackbrickwebsite.s3.me-central-1.amazonaws.com/JGEgeneric.jpg",
        stats: [
          { label: "Known For", text: "Championship golf courses and premium villas." },
          { label: "Highlights", text: "Golf communities, lush greenery, and family-friendly amenities." },
          { label: "Ideal For", text: "Golf enthusiasts and families who love luxury community living." },
        ],
      },
      {
        name: "Al Barari",
        image: "https://blackbrickwebsite.s3.me-central-1.amazonaws.com/albararigeneric.jpg",
        stats: [
          { label: "Known For", text: "Eco-luxury lifestyle and lush greenery." },
          { label: "Highlights", text: "Themed gardens, bespoke villas, wellness-focused community." },
          { label: "Ideal For", text: "Nature lovers and those seeking tranquility and exclusivity." },
        ],
      },
      {
        name: "Arabian Ranches",
        image: "https://blackbrickwebsite.s3.me-central-1.amazonaws.com/arGeneric.jpg",
        stats: [
          { label: "Known For", text: "Iconic desert-themed villa community." },
          { label: "Highlights", text: "Golf course, equestrian facilities, and top-tier schools." },
          { label: "Ideal For", text: "Families and expats looking for suburban comfort with modern conveniences." },
        ],
      },
    ],
    [websitesettings]
  );

  // Render collection cards
  const renderCollectionCards = () => {
    if (isPending) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <p>Failed to load data. Please try again later.</p>;
    }

    return collections.map((collection) => (
      <div key={collection.name} className="community-card">
        <img src={collection.image} alt={collection.name} className="community-card-image" />
        <div className="community-card-content">
          <h3>{collection.name}</h3>
          <div className="community-card-stats">
            {collection.stats.map((stat) => (
              <div key={stat.label}>
                <span className="stats-label">{stat.label}: </span>
                <span>{stat.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="community-heading-container">
        <h1>Discover Dubai's Finest Residences</h1>
      </div>
      <div className="community-offering-cards">{renderCollectionCards()}</div>
    </>
  );
};

export default Communities;
