import React, { useEffect, useState } from "react";
import { parse,addDays, format, eachDayOfInterval, isWeekend, addHours, isAfter, startOfDay,addMinutes } from "date-fns";

const PhotographerTimeSlot = ({ formData, photographers, setFormData, getAvailableSlots, formatDate, isEditable }) => {
  const [availableDates, setAvailableDates] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState([]);

  // Adding the new photographer to the photographers array
  const newPhotographers = photographers.concat([{
    name: "Mazyad Aljaramani",
    image: "https://blackbrickwebsite.s3.me-central-1.amazonaws.com/0526199709+(1).jpg"
  }]);

  // Define Mazyad's leave period (Nov 29 to Dec 6)
  const mazyadLeaveStart = new Date(2024, 10, 29); // Nov 29, 2024 (month is zero-based)
  const mazyadLeaveEnd = new Date(2024, 11, 6); // Dec 6, 2024

  useEffect(() => {
    const generateWeekdays = () => {
      const today = new Date();
      const startDate = isEditable ? today : addHours(today, 24); // Start from now if editable, else 24 hours later
      const endDate = addDays(today, 30); // End date is 30 days from today
      const allDates = eachDayOfInterval({ start: startOfDay(startDate), end: endDate });
      const weekdays = allDates.filter(date => !isWeekend(date)); // Filter out weekends
      setAvailableDates(weekdays);
    };
    generateWeekdays();
  }, [isEditable]);

  useEffect(() => {
    const filterSlotsForDate = () => {
      if (formData.date) {
        const selectedDate = new Date(formData.date);
        const now = new Date();
        
        // For filtering slots, use a 24-hour threshold if not editable
        const timeThreshold = isEditable ? now : addHours(now, 24);

        const availableSlots = getAvailableSlots().filter((slot) => {
          const [hours, minutes] = slot.split(":").map(Number);
          const slotTime = addHours(startOfDay(selectedDate), hours).setMinutes(minutes);

          return isAfter(slotTime, timeThreshold); // Only show slots after the time threshold
        });
        setFilteredSlots(availableSlots);
      }
    };
    filterSlotsForDate();
  }, [formData.date, getAvailableSlots, isEditable]);

  // Handle selecting a photographer, show alert if Mazyad is selected during his leave period
  const handlePhotographerSelect = (photographerName) => {
    const selectedDate = new Date(formData.date);

    if (photographerName === "Mazyad Aljaramani" && selectedDate >= mazyadLeaveStart && selectedDate <= mazyadLeaveEnd) {
      // Show alert if Mazyad is selected during his leave
      alert("Mazyad Aljaramani is on leave from Nov 29 to Dec 6. Please select another photographer.");
    } else {
      // Otherwise, proceed to set the photographer
      setFormData((prev) => ({ ...prev, photographer: photographerName }));
    }
  };

  // Handle selecting multiple consecutive time slots

  // Handle selecting multiple consecutive time slots
  const handleTimeSlotSelect = (slot) => {
    const selectedSlots = [...formData.timeSlot]; // Get current selected slots
    const lastSelectedSlot = selectedSlots[selectedSlots.length - 1];
  
    // Parse the selected slot (e.g., "16:00") into a Date object
    const selectedSlotTime = parse(slot, "HH:mm", new Date());
  
    if (selectedSlots.includes(slot)) {
      // If the slot is already selected, unselect it
      setFormData((prev) => ({
        ...prev,
        timeSlot: selectedSlots.filter((selected) => selected !== slot),
      }));
    } else {
      if (selectedSlots.length === 0) {
        // If no slots are selected, allow the first slot
        setFormData((prev) => ({
          ...prev,
          timeSlot: [...selectedSlots, slot],
        }));
      } else {
        // Parse the last selected slot time into a Date object
        const lastSlotTime = parse(lastSelectedSlot, "HH:mm", new Date());
  
        // Check if the selected slot is exactly one hour after the last selected slot
        const nextSlotTime = addMinutes(lastSlotTime, 60);
  
        // If the selected slot is exactly 1 hour after the last selected slot, allow selection
        if (isAfter(selectedSlotTime, lastSlotTime) && selectedSlotTime.getTime() === nextSlotTime.getTime()) {
          setFormData((prev) => ({
            ...prev,
            timeSlot: [...selectedSlots, slot],
          }));
        } else {
          // Show an alert if the selected slot is not consecutive
          alert("You can only select consecutive time slots.");
        }
      }
    }
  };
  
  

  return (
    <section>
      <h3>Step 4: Photographer and Time Slot</h3>

      <label>Select Date *</label>
      <div className="date-selection-scrollable">
        {availableDates.map((date) => (
          <button
            type="button"
            key={date}
            className={`photographerselection date-box ${formData.date === format(date, "yyyy-MM-dd") ? "selected" : ""}`}
            onClick={() => setFormData((prev) => ({ ...prev, date: format(date, "yyyy-MM-dd") }))}
          >
            <div className="day">{format(date, "EEE")}</div>
            <div className="date">{format(date, "dd")}</div>
            <div className="month-year">{format(date, "MMM yyyy")}</div>
          </button>
        ))}
      </div>
      <label>Select Photographer</label>
      <div className="photographer-selection">
        {newPhotographers.map((photographer) => (
          <button
            key={photographer.name}
            type="button"
            className={`photographerselection photographer-option ${formData.photographer === photographer.name ? "selected" : ""}`}
            onClick={() => handlePhotographerSelect(photographer.name)} // Use the new handler here
          >
            <img src={photographer.image} alt={photographer.name} className="photographer-image" />
            <span className="photographer-name">{photographer.name}</span>
          </button>
        ))}
      </div>

      <label>Select Time Slot *</label>
      <div className="time-slot-buttons">
        {filteredSlots.map((slot) => (
          <button
            type="button"
            key={slot}
            className={`photographerselection time-slot-button ${formData.timeSlot.includes(slot) ? "selected" : ""}`}
            onClick={() => handleTimeSlotSelect(slot)}
          >
            {slot}
          </button>
        ))}
      </div>
    </section>
  );
};

export default PhotographerTimeSlot;
