import React from 'react';
import './Quote.css';

const Quote = ({ heading, text }) => (
  <div className="quote-container">
    {heading && <h2 className="quote-heading-text">{heading}</h2>} {/* Display heading if provided */}
    <p className="quote-text">{text}</p>
  </div>
);

export default Quote;
