import React, { useState,useRef } from 'react';
import './Banner.css';

const Banner = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = React.useRef(null);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const contactFormRef = useRef(null); // Reference to ContactForm

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight, // Scroll to the bottom of the page
      behavior: 'smooth', // Smooth scrolling animation
    });
  };


  return (
    <div className="banner-container">
      <header className="banner-navigation">
        <div className="banner-logo">
          <img src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/BlackBrick-Black.png" alt="Logo" />
        </div>
        <nav className="banner-nav-links">
          <a href="#about">About</a>
          <a href="#features">Features</a>
          <a href="#testimonials">Testimonials</a>
          <a onClick={scrollToBottom}>Contact</a>
        </nav>
      </header>
      <video ref={videoRef} autoPlay loop muted>
        <source
          src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/Family+Relocating+Stock.mp4"
          type="video/mp4"
        />
      </video>
      <div className="banner-overlay">
        <div className="banner-content">
          <h1>Your New Life Awaits in Dubai</h1>
          <p>Discover luxury living and endless opportunities in the heart of the UAE.</p>
          <a onClick={scrollToBottom} className="banner-cta-button">START YOUR JOURNEY TODAY</a>
        </div>
        {/* <button className="banner-play-pause-button" onClick={togglePlayPause}>
          {isPlaying ? '❚❚' : '▶'}
        </button> */}
      </div>
      <div className="banner-shortcuts">
        <a href= {`https://wa.me/+971549977380?text=${encodeURIComponent(`Hello, I would like more information for relocation! https://www.blackbrickproperty.com/investor`)}`} target='_blank' className="banner-whatsapp" aria-label="WhatsApp">
          <i className="fab fa-whatsapp"></i> 
        </a>
      </div>
    </div>
  );
};

export default Banner;