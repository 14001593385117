import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [open, setOpen] = useState(null); // Change to null to allow toggling between questions

  const faqs = [
    {
      question: "What is a Golden Visa, and how can I qualify for it?",
      answer: "If you want to live, study, or work in Dubai without the need for a local sponsor, the Golden Visa is the best option. There are many ways to qualify for this visa, including investing in a property worth at least AED 2 million. Talented individuals, doctors, and entrepreneurs can also apply."
    },
    {
      question: "Can I work or start a business in Dubai with a Golden Visa?",
      answer: `The Golden Visa also allows you to work and start a business in Dubai without requiring a local partnership. Additionally, you can enjoy tax-free profits in certain cases, depending on the region where your business is established`
    },
    // Add more FAQs here
    {
      question: "What is the process for relocating to Dubai, and how long does it take?",
      answer: "The basic steps for a smooth relocation include buying or renting a property. If your investment exceeds AED 2 million, you’ll also be eligible to apply for the Golden Visa. Arranging utilities, schooling, and healthcare is a straightforward process that won’t take too long. At BlackBrick, we are here to help you find the perfect home and guide you through every step of the relocation process. Our advisors are residents in family-centered communities, providing you with expert insights and all the information you need to settle in comfortably."
    },
    {
      question: "Is it safe for families to move to Dubai?",
      answer: "Safety is one of the main reasons UK families choose to relocate to Dubai. The city's strict laws and technologically advanced systems ensure order and peace, making it an ideal place to raise your family"
    },
    {
      question: "Is Dubai welcoming to people of all religions, ethnicities, and traditions?",
      answer: "Over 200 nationalities live harmoniously in Dubai, where culture and traditions are celebrated. Residents have the unique opportunity to connect and interact with diverse communities, enriching their lives with a wealth of cultural knowledge and experiences"
    }
  ];

  const toggleQuestion = (index) => {
    if (open === index) {
      setOpen(null); // Close if clicked again
    } else {
      setOpen(index); // Open the selected question
    }
  };

  return (
    <div className="faq">
      <h2 className="faq-title">Frequently Asked Questions</h2>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <div className="faq-question" onClick={() => toggleQuestion(index)}>
              <h3>{faq.question}</h3>
              <span className="faq-toggle-icon">{open === index ? '-' : '+'}</span>
            </div>
            {open === index && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
