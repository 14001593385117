import React from 'react';
import './Testimonials.css';

const testimonialsData = [
  {
    name: "John Doe",
    image: "https://randomuser.me/api/portraits/men/1.jpg",
    text: "Amazing services and seamless relocation!",
    rating: 5,
  },
  {
    name: "Sarah Smith",
    image: "https://randomuser.me/api/portraits/women/1.jpg",
    text: "Highly recommend BlackBrick for your move.",
    rating: 4,
  },
  {
    name: "Michael Brown",
    image: "https://randomuser.me/api/portraits/men/2.jpg",
    text: "Professional team and great experience throughout.",
    rating: 5,
  },
];

const Testimonials = () => (
  <div className="testimonials-section">
    <h2>What Our Clients Say</h2>
    <div className="testimonial-cards-container">
      {testimonialsData.map((testimonial, index) => (
        <div className="testimonial-card" key={index}>
          <div className="testimonial-avatar">
            <img src={testimonial.image} alt={testimonial.name} />
          </div>
          <div className="testimonial-text">
            <p>"{testimonial.text}"</p>
            <div className="testimonial-rating">
              {Array.from({ length: 5 }, (_, i) => (
                <span key={i} className={i < testimonial.rating ? "filled-star" : "empty-star"}>★</span>
              ))}
            </div>
          </div>
          <div className="testimonial-name">
            <p>{testimonial.name}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Testimonials;
