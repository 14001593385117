import React, { useEffect, useRef } from "react";

function GoogleMap({ onSaveLink }) {
  const mapRef = useRef(null);
  const searchBoxRef = useRef(null);
  const markerRef = useRef(null); // Keep marker reference
  const mapInstance = useRef(null); // Keep map instance

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCr8gUuxHjGaun4OPFzy6xyXGYVXAKfDBI&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initMap;
      document.head.appendChild(script);
    } else {
      initMap();
    }
  }, []);

  const initMap = () => {
    const google = window.google;

    // Initialize the map
    const map = new google.maps.Map(mapRef.current, {
      center: { lat: 25.276987, lng: 55.296249 }, // Dubai
      zoom: 14,
      mapTypeControl: false,
      streetViewControl: false,
      gestureHandling: "cooperative", // Make gestures smoother
    });
    mapInstance.current = map;

    // Add a marker at the center and make it draggable
    const marker = new google.maps.Marker({
      position: map.getCenter(),
      map,
      draggable: true, // Enable dragging of the marker
      animation: google.maps.Animation.DROP, // Start marker with a drop animation
    });
    markerRef.current = marker;

    // Update marker position and map link when the marker is dropped
    marker.addListener("dragend", () => {
      const position = marker.getPosition();
      map.panTo(position); // Re-center map on the marker
      const newMapLink = `https://www.google.com/maps?q=${position.lat()},${position.lng()}`;
      onSaveLink(newMapLink); // Pass updated link to parent
    });

    // Update marker position when map is idle (user dragging the map)
    map.addListener("idle", () => {
      const center = map.getCenter();
      marker.setPosition(center);

      const newMapLink = `https://www.google.com/maps?q=${center.lat()},${center.lng()}`;
      onSaveLink(newMapLink); // Pass updated link to parent
    });

    // Add autocomplete to the search box
    const input = searchBoxRef.current;
    if (input) {
      const autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.bindTo("bounds", map);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          // Smoothly pan to the selected place
          map.panTo(place.geometry.location);
          map.setZoom(15);

          // Animate the marker movement
          const newMarkerPosition = place.geometry.location;
          marker.setPosition(newMarkerPosition);
          marker.setAnimation(google.maps.Animation.BOUNCE); // Bouncing effect
          setTimeout(() => {
            marker.setAnimation(null); // Stop the bounce after a short time
          }, 1000);

          // Update the map link
          const newMapLink = `https://www.google.com/maps?q=${newMarkerPosition.lat()},${newMarkerPosition.lng()}`;
          onSaveLink(newMapLink);
        } else {
          console.error("Selected place has no geometry.");
        }
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior (like form submission)
      e.stopPropagation(); // Prevent the event from triggering other listeners
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Search box */}
      <input
        ref={searchBoxRef}
        type="text"
        placeholder="Search for a place"
        style={{
          position: "absolute",
          top: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 5,
          padding: "10px",
          width: "250px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
        onKeyPress={handleKeyPress} // Listen for the key press event
      />
      {/* Google Map container */}
      <div
        ref={mapRef}
        style={{ marginTop: "60px", height: "400px", width: "100%" }}
      />
    </div>
  );
}

export default GoogleMap;
