import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/footer";
import { saveEventLead, getRegistrationCount } from "../context/endpoints";
import { useLocation, useParams } from "react-router-dom";
import "./eventDetails.css";

const EventDetails = () => {
  const { title } = useParams();
  console.log("title", title);
  
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEventFull, setIsEventFull] = useState(false);
  
  const eventDetails = {
    "realcost": {
      title: "The Real Cost of Renovation",
      image: "https://blackbrickwebsite.s3.me-central-1.amazonaws.com/RenovationPanel+Invite+_Landing+Page+black.png",
      eventName: "Real Cost of Renovation",
      finalMessage: "Thank you for registering! Our seats are currently full, but you are on the waiting list. We will contact you as soon as new slots are available."
    },
    "maison-mathis": {
      title: "Maison Mathis",
      image: "https://blackbrickwebsite.s3.me-central-1.amazonaws.com/Maison+Mathis+Invite_Landing+Page.png",
      eventName: "Maison Mathis",
      finalMessage: "Thank you! You will receive the invite confirmation by email."
    },
    "sf-evening" :{
      title: "An Evening Just For You",
      eventName :"An Evening Just For You",
      image :"https://blackbrickwebsite.s3.me-central-1.amazonaws.com/Sanctuary+Falls+Event+Invite+(1).png",
      finalMessage: "Thank you! You will receive the invite confirmation by email.",
      "Date": "Thursday 7th November",
      "Time": "7:00 - 9:30pm",
      "Location": "Sanctuary Falls, Jumeirah Golf Estates",
      "Link": "https://maps.app.goo.gl/Loho2fAidG2h3M8m8?g_st=com.google.maps.preview.copy"
    },
    "barari-afterhour" :{
      title: "Al Barari after hour",
      eventName :"Al Barari after hour",
      image :"https://blackbrickwebsite.s3.me-central-1.amazonaws.com/Albarari+night+hideout+Event+Invite+updated.png",
      finalMessage: "Thank you! You will receive the invite confirmation by email.",
      "Date": "Thursday 14th November",
      "Time": "7:00 - 9:00pm",
      "Location": "The Hideout, Al Barari",
      "Link": "https://maps.app.goo.gl/jv2zTdnRcf2obPqTA"
    },
    "emirates-social" :{
      title: "Your Community Social",
      eventName: "Your Community Social",
      image :"https://blackbrickwebsite.s3.me-central-1.amazonaws.com/Social+reform+event+_Landing+page.png",
      finalMessage: "Thank you! You will receive the invite confirmation by email.",
      "Date": "Thursday 19th November",
      "Time": "7:00 - 9:00pm",
      "Location": "Reform Social & Grill, The Lakes",
      "Link": "https://maps.app.goo.gl/oDJ77gdUUNgGbYP37"
    },
    "golf-afternoon":{
      title: "An afternoon on The Greens",
      eventName: "An afternoon on The Greens",
      image :"https://blackbrickwebsite.s3.me-central-1.amazonaws.com/Tee+Off+into+The+Afternoon+AR_Landing+page+(1).png",
      finalMessage: "Thank you! You will receive the invite confirmation by email.",
      "Date": "Friday 22nd November",
      "Time": "3:30 - 7:00pm",
      "Location": "An afternoon on The Greens",
      "Link": "https://maps.app.goo.gl/oDJ77gdUUNgGbYP37"
    },
    "friendly-barari":{
      title: "Barari Exclusive",
      eventName: "Barari Exclusive",
      image :"https://blackbrickwebsite.s3.me-central-1.amazonaws.com/Al+Barari+PAdel_02_updated.png",
      finalMessage: "Thank you! You will receive the invite confirmation by email.",
      "Date": "Friday 14th December",
      "Time": "9:00 - 1:00pm",
      "Location": "Body Language Padel Court",
      "Link": "https://maps.app.goo.gl/Fe9ct7nn5LfXATmB8"
    },
    "morning-flow": {
      title: "Morning Flow & Connection",
      eventName: "Morning Flow & Connection",
      image: "https://blackbrickwebsite.s3.me-central-1.amazonaws.com/Wellness+invite_landing+page01+(1).jpg",
      finalMessage: "Thank you! You will receive the invite confirmation by email.",
      "Date": "Thursday 28th November",
      "Time": "8:00 - 9:00am",
      "Location": "Springs 2 East Park",
      "Link": "https://maps.app.goo.gl/ZX8ghR9kqKXtknot6",
      "ProgramType":["Mummy & Baby Pilates by Pheonix Rising", "Energising Workout by Willpower"]
    },
    // Add other events as before...
  };

  const currentEvent = eventDetails[title] || eventDetails["realcost"]; // Default to realcost if title is unknown

  useEffect(() => {
    const fetchRegistrationCount = async () => {
      const {count} = await getRegistrationCount(title);
      if (title === "golf-afternoon" && count >= 36) {
        setIsEventFull(true);
      }
      else if (title ==="friendly-barari" && count >20){
        setIsEventFull(true);
      }
    };
    fetchRegistrationCount();
  }, [title]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (step === 1 && !formData.name) {
      setErrors({ ...errors, name: "Name is required" });
      return;
    }
    if (step === 2) {
      if (!formData.email) {
        setErrors({ ...errors, email: "Email is required" });
        return;
      } else if (!validateEmail(formData.email)) {
        setErrors({ ...errors, email: "Invalid email format" });
        return;
      }
    }
    setStep(step + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.phone) {
      setErrors({ ...errors, phone: "Phone number is required" });
      return;
    }
    if (title === "morning-flow" && !formData.programType) {
      setErrors({ ...errors, programType: "Program type is required" });
      return;
    }
    setIsSubmitting(true);
    try {
      const sanitizedData = {
        url: window?.location?.href,
        name: formData.name.trim(),
        email: formData.email.trim(),
        phone: formData.phone.trim(),
        title: currentEvent.title,
        programType: formData.programType // Include the selected program type
      };
      await saveEventLead(sanitizedData);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error saving lead data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{currentEvent.title}</title>
      </Helmet>
      <Header />
      <div className="event-details">
        <div className="event-image">
          <img src={currentEvent.image} alt="Event Flyer" />
        </div>
        <div className="registration-section">
          <h2>Register for the Event</h2>
          {isEventFull && title === "golf-afternoon" && (
            <p className="event-full-message">
              <strong>EVENT IS FULL</strong>. Unfortunately, we are currently at full capacity for the golf. However, we’d love for you to join us for drinks at Birdie’s after the game.
              <p>You can also subscribe to our waiting list, and we’ll notify you immediately if any slots become available.</p>
            </p>
          )}
          {isSubmitted ? (
            <p className="confirmation-message">{currentEvent.finalMessage}</p>
          ) : (
            <form onSubmit={handleSubmit} className="registration-form">
              {step === 1 && (
                <>
                  <label>
                    What's your name?
                    <div className="input-container">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                      {errors.name && <div className="error-message">{errors.name}</div>}
                      <button onClick={handleNext} className="next-button">
                        Next <span className="arrow">→</span>
                      </button>
                    </div>
                  </label>
                </>
              )}
              {step === 2 && (
                <>
                  <label>
                    What is your email?
                    <div className="input-container">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      {errors.email && <div className="error-message">{errors.email}</div>}
                      <button onClick={handleNext} className="next-button">
                        Next <span className="arrow">→</span>
                      </button>
                    </div>
                  </label>
                </>
              )}
              {step === 3 && !isEventFull && (
                <>
                  <label>
                    What's your phone number?
                    <div className="input-container">
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                      {errors.phone && <div className="error-message">{errors.phone}</div>}
                     {title !=="morning-flow"&& <button type="submit" className="register-button" disabled={isSubmitting}>
                        {isSubmitting ? 'Registering...' : 'Register'}
                      </button>}
                      {title ==="morning-flow"&& <button onClick={handleNext} className="next-button">
                        Next <span className="arrow">→</span>
                      </button>}
                    </div>
                  </label>
                </>
              )}
              {step === 4 && title === "morning-flow" && (
                <>
                  <label>
                      Select Program Type:
                      <div className="input-container">
                        <select
                          name="programType"
                          value={formData.programType}
                          onChange={handleChange}
                          required
                          className="landing-selection"
                        >
                          <option value="">-- Select Program Type --</option>
                          {currentEvent.ProgramType.map((program, index) => (
                            <option key={index} value={program}>
                              {program}
                            </option>
                          ))}
                        </select>
                        {errors.programType && <div className="error-message">{errors.programType}</div>}
                        <button type="submit" className="register-button" disabled={isSubmitting}>
                          {isSubmitting ? 'Registering...' : 'Register'}
                        </button>
                      </div>
                    </label>
                </>
              )}
            </form>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EventDetails;
