import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/footer";
import { fetchMediaCoverages, cancelMediaCoverage } from "../context/endpoints"; // Adjust the import path accordingly
import "./mediaCoverageList.css";

const MediaCoverageList = () => {
    const [mediaCoverages, setMediaCoverages] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filteredCoverages, setFilteredCoverages] = useState([]);
    const [password, setPassword] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const staticPassword = "bbp@1114";
    const navigate = useNavigate();

    useEffect(() => {
        const getMediaCoverages = async () => {
            try {
                const coverages = await fetchMediaCoverages();
                setMediaCoverages(coverages);
            } catch (error) {
                console.error("Error fetching media coverages:", error);
            }
        };

        getMediaCoverages();
    }, []);

    useEffect(() => {
        const filtered = mediaCoverages.filter((coverage) => {
            const coverageDate = new Date(coverage.date);
            const isWithinSearch =
                coverage.advisor.toLowerCase().includes(searchTerm.toLowerCase()) ||
                coverage.photographer.toLowerCase().includes(searchTerm.toLowerCase());
            const isWithinDateRange =
                (!startDate || coverageDate >= new Date(startDate)) &&
                (!endDate || coverageDate <= new Date(endDate));
            return isWithinSearch && isWithinDateRange;
        });
        setFilteredCoverages(filtered);
    }, [searchTerm, startDate, endDate, mediaCoverages]);

    const handleEdit = (coverage) => {
        navigate(`/media/coverage/${coverage._id}`, { state: { coverage } });
    };

    const handlePreview = (coverage) => {
        navigate(`/media/preview/${coverage._id}`);
    };

    const handleCancel = async (id) => {
        try {
            const response = await cancelMediaCoverage(id);
            if (response) {
                setMediaCoverages((prevCoverages) =>
                    prevCoverages.map((coverage) =>
                        coverage._id === id ? { ...coverage, status: "Cancelled" } : coverage
                    )
                );
                setFilteredCoverages((prevCoverages) =>
                    prevCoverages.map((coverage) =>
                        coverage._id === id ? { ...coverage, status: "Cancelled" } : coverage
                    )
                );
            }
        } catch (error) {
            console.error("Error cancelling media coverage:", error);
        }
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === staticPassword) {
            setIsAuthenticated(true);
        } else {
            alert("Incorrect password, please try again.");
        }
    };

    return (
        <>
            <Helmet>
                <title>Media Coverage List</title>
            </Helmet>
            <Header />
            <div className="media-coverage-list-container">
                {!isAuthenticated ? (
                    <form onSubmit={handlePasswordSubmit} className="password-form">
                        <h2 className="mc-mainheading">Enter Password to Access</h2>
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button className="search-person" type="submit">Submit</button>
                    </form>
                ) : (
                    <>
                        <h1>Media Coverage List</h1>
                        <div className="filter-container">
                            <input
                                type="text"
                                className="input-media"
                                placeholder="Search by Advisor & Ads"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                                <div className="date-filters" style={{paddingBottom:"20px"}}>
                                <div className="date-input-wrapper">
                                    <input
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        className="date-input"
                                        id="start-date"
                                    />
                                    {!startDate && <label htmlFor="start-date" className="date-placeholder">Start Date</label>}
                                </div>
                                <div className="date-input-wrapper">
                                    <input
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        className="date-input"
                                        id="end-date"
                                    />
                                    {!endDate && <label htmlFor="end-date" className="date-placeholder">End Date</label>}
                                </div>
                            </div>

                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Advisor</th>
                                    <th>Listing Status</th>
                                    <th>Photographer</th>
                                    <th>Date</th>
                                    <th>Time Slot</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredCoverages.map((coverage) => (
                                    <tr key={coverage._id}>
                                        <td>{coverage.advisor}</td>
                                        <td>{coverage.listingStatus}</td>
                                        <td>{coverage.photographer}</td>
                                        <td>{new Date(coverage.date).toLocaleDateString()}</td>
                                        <td>{coverage.timeSlot?.join(", ")}</td>
                                        <td>{coverage.status}</td>
                                        <td>
                                            <button className="edit-button" onClick={() => handleEdit(coverage)}>Edit</button>
                                            <button className="preview-button" onClick={() => handlePreview(coverage)}>Preview</button>
                                            {coverage.status !== "Cancelled" && (
                                                <button className="cancel-button" onClick={() => handleCancel(coverage._id)}>Cancel</button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
            <Footer />
        </>
    );
};

export default MediaCoverageList;
