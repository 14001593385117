import React from 'react';
import './WhyDubai.css';

const WhyDubai = () => (
  <div className="why-dubai-container">
    <h1 className="why-dubai-main-heading">Why Choose Dubai?</h1>
    <div className="why-dubai-section">
      <div className="why-dubai-text-container">
        <h1>A Lifestyle Beyond Compare</h1>
        <p>Beyond being just a city, Dubai is a lifestyle—a promise of a brighter future for you and your family. Home to over 3.5 million residents from multiple nationalities, Dubai offers exceptional education, entertainment for all tastes, and world-class healthcare and wellness facilities. From relaxing on pristine beaches to exploring the majestic desert, Dubai blends luxury and adventure like no other nation in the world. Ranked among the top 10 safest cities globally, it provides a secure environment, making it an ideal place to live, work, and raise a family.</p>
      </div>
      <div className="why-dubai-image-container">
        <img
          src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/landinf-com1.jpg"
          alt="Dubai view"
          className="why-dubai-image"
        />
      </div>
    </div>
    <div className="why-dubai-section why-dubai-reverse">
      <div className="why-dubai-text-container">
        <h1>The Economic Powerhouse of Opportunity</h1>
        <p>Dubai's GDP exceeds $120 billion, solidifying its status as an economic powerhouse. The city boasts a highly flexible banking system and well-organized processes for company establishment, making it a magnet for entrepreneurs and investors. Add to this the allure of zero income tax, and Dubai emerges as a city designed to help you thrive both personally and professionally. Whether you're seeking stability, opportunity, or adventure, Dubai truly has it all.</p>
      </div>
      <div className="why-dubai-image-container">
        <img
          src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/landing-com2.jpg"
          alt="Dubai skyline"
          className="why-dubai-image"
        />
      </div>
    </div>
  </div>
);

export default WhyDubai;
