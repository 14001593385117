import React from 'react';
import Banner from '../components/investorPage/Banner';
import Quote from '../components/investorPage/Quote';
import FeaturedProperties from '../components/investorPage/FeaturedProperties';
import WhyDubai from '../components/investorPage/WhyDubai';
import FAQ from '../components/investorPage/FAQ';
import Communities from '../components/investorPage/Communities';
import Testimonials from '../components/investorPage/Testimonials';
import WhyChooseUs from '../components/investorPage/WhyChooseUs';
import ContactForm from '../components/investorPage/ContactForm';
import Collections from "../components/home/collections";
import "./investorPage.css"
const InvestorPage = () => {
  return (
    <div className='relocation-page'>
      <Banner />
      <Quote text="
             Relocation is more than a move; it's the dawn of a brighter chapter, a new journey filled with promise and opportunity for you and your family.."
        />
      <FeaturedProperties properties={properties} />
      <WhyDubai />
      <FAQ />
      <Communities />
      <Testimonials />
      <WhyChooseUs />
      <ContactForm />
    </div>
  );
};

const properties = [
    {
      title: "Luxury Apartment",
      firstImage: "Starting at $1,200,000",
      image: "https://images.goyzer.com/uf/5063/unit/9283/unnamed_-1-.png?group=5063",
    },
    {
      title: "Beachfront Villa",
      subtitle: "Starting at $3,500,000",
      image: "https://images.goyzer.com/uf/5063/unit/9227/DSC03761.jpg?group=5063",
    },
    {
      title: "Penthouse",
      subtitle: "Starting at $2,800,000",
      image: "https://images.goyzer.com/uf/5063/unit/9177/MAZY5247.jpg?group=5063",
    },
  ];

export default InvestorPage;
